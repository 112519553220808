<template>
  <!-- class="fullScreen" -->
  <div :class="{ show: showEditDialog, fullScreen: distriBution }">
    <div v-loading="loading">
      <Crumbs v-if="distriBution">
        <el-breadcrumb-item slot="after"> 个人绩效详情 </el-breadcrumb-item>
      </Crumbs>
      <div :class="{ fullScreenMain: distriBution }">
        <div :class="{ fullScreenMainCon: distriBution }">
          <template v-if="businessInformation">
            <el-descriptions style="margin-top: 10px" direction="vertical" :column="5" border>
              <template slot="title">
                <div class="info-title-with-button">
                  <div style="font-size: 16px; color: #000; font-weight: 700; margin-bottom: 5px">
                    商务基础信息
                  </div>
                  <el-button type="success" size="small">
                    <a
                      target="_blank"
                      href=" https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/项目商务绩效分配方法.pdf"
                      >绩效分配规则说明</a
                    ></el-button
                  >
                </div>
              </template>
              <el-descriptions-item label="商务名称" :span="2">
                {{ businessInformation.businessName || '' }}
              </el-descriptions-item>
              <el-descriptions-item label="商务类型" :span="1">
                {{ businessInformation.businessType | dict(dictData.businessType) }}
              </el-descriptions-item>
              <el-descriptions-item label="项目来源" :span="1">
                {{ businessInformation.projectSource | dict(dictData.projectSource) }}
              </el-descriptions-item>
              <el-descriptions-item label="业务种类" :span="1">
                {{ businessInformation.businessLines | dict(dictData.businessLines) }}
              </el-descriptions-item>

              <el-descriptions-item label="业主单位" :span="3">
                {{ businessInformation.ownerUnit || '' }}
              </el-descriptions-item>
              <el-descriptions-item label="项目承接公司" :span="1">
                {{ businessInformation.companyType | dict(dictData.companyType) }}
              </el-descriptions-item>
              <el-descriptions-item label="实际商务负责人" :span="1">
                {{ businessInformation.principalUserName | addDept(CompanyMembers) }}
              </el-descriptions-item>
            </el-descriptions>
          </template>

          <el-descriptions
            style="margin-top: 10px; margin-bottom: 10px"
            title="商务费用结算"
            direction="vertical"
            :column="4"
            border
          >
            <template slot="extra">
              <span style="font-size: 12px; color: gray">单位：元</span>
            </template>
            <el-descriptions-item
              label="我方合同金额"
              :span="2"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              {{ businessInformation.ourContractAmount || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              <template slot="label">
                税费总计
                <span style="font-size: 12px; color: red; padding-left: 20px">
                  包含增值税、附加税、印花税
                </span>
              </template>
              {{ businessInformation.taxesFee || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              label="招投标费用"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              {{ businessInformation.tenderFee || 0 }}
            </el-descriptions-item>
            <!-- <el-descriptions-item
              label="预计外部协作费用"
              :span="1"
              label-class-name="my-label"
              content-class-name="my-content"
            >
              {{ businessInformation.exteriorBusinessCosts || 0 }}
            </el-descriptions-item> -->
            <!-- <el-descriptions-item :span="2">
              <template slot="label">
                预计可支配金额
                <span style="font-size: 12px; color: red; padding-left: 20px">
                  预计可支配金额 = 合同金额 - 税费 - 招投标费用 - 预计外部协作费用
                </span>
              </template>
              {{ businessInformation.predictDisposableProjectExpense || 0 }}
            </el-descriptions-item> -->
            <!-- <el-descriptions-item :span="1">
              <template slot="label">
                <div>
                  内部生产费用
                  <el-button
                    style="margin-left: 20px"
                    type="primary"
                    size="mini"
                    @click="showNBSCFY"
                    :loading="isNBSCFYButtonLoading"
                    >查看比例</el-button
                  >
                </div>
                <div style="margin-top: 5px">
                  <span style="font-size: 12px; color: red">
                    内部生产费用 = 预计可支配金额 X 生产费用比例
                  </span>
                </div>
              </template>
              {{ businessInformation.productionCost || 0 }}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label">
                <div>
                  外协生产费用
                  <el-button
                    style="margin-left: 20px"
                    type="primary"
                    size="mini"
                    @click="showWXSCFY"
                    :loading="isWXSCFYButtonLoading"
                    >查看详情</el-button
                  >
                </div>
                <div style="margin-top: 5px; opacity: 0%">
                  <span style="font-size: 12px; color: red"> 外协生产费用 </span>
                </div></template
              >
              {{ businessInformation.projectOutsourceByBusiness || 0 }}
            </el-descriptions-item> -->
            <el-descriptions-item :span="2">
              <template slot="label">
                结算可支配金额
                <div style="margin-top: 5px">
                  <span style="font-size: 12px; color: red">
                    结算可支配金额 = （预计可支配金额 - 内部生产费用 - 外协生产费用）X 25%
                  </span>
                </div>
              </template>
              {{ businessInformation.interiorBusinessPerformance || 0 }}
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label">
                <div>商务部管理费用</div>
                <div style="margin-top: 5px">
                  <span style="font-size: 12px; color: red">
                    商务部管理费用 = 结算可支配金额 X 20%
                  </span>
                </div>
              </template>
              {{ businessInformation.businessDeptPerformance || 0 }}
            </el-descriptions-item>
            <el-descriptions-item
              label-class-name="projectBGC"
              content-class-name="projectBGC"
              :span="1"
            >
              <template slot="label"> 实际绩效金额 </template>
              {{ businessInformation.actualPerformance || 0 }}
            </el-descriptions-item>
          </el-descriptions>

          <div class="title-with-button">
            <div style="font-size: 16px; color: #000; font-weight: 700; margin-bottom: 5px">
              绩效核算
            </div>
            <el-button
              type="primary"
              size="small"
              style="margin-left: 40px"
              @click="showCostDialog"
              :loading="isCostDialogLoading"
              >查看费用信息</el-button
            >
          </div>

          <el-table :data="tableData[Index]" border style="width: 100%">
            <!-- <el-table-column align="center" type="index" label="序号" width="40"> </el-table-column> -->
            <el-table-column
              align="center"
              prop="workContent"
              label="工作内容"
              :show-overflow-tooltip="false"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="participatingDistributor"
              label="参与角色"
              :show-overflow-tooltip="false"
            >
            </el-table-column>

            <el-table-column
              align="center"
              label="绩效占比"
              width="150"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{
                  scope.row.contentType == 'MONEY_TYPE'
                    ? scope.row.money
                      ? scope.row.money + '元'
                      : 0 + '元'
                    : scope.row.ratio
                    ? scope.row.ratio + '%'
                    : 0 + '%'
                }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              label="绩效总额"
              width="150"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                {{ scope.row.performanceTotalMoneyFormat || 0 }}元
              </template>
            </el-table-column>

            <!-- <el-table-column align="center" label="事项说明" :show-overflow-tooltip="false">
              <template slot-scope="scope">
                <el-input
                  disabled
                  v-model="scope.row.instructionMatters"
                  type="textarea"
                  :autosize="{ minRows: 2 }"
                ></el-input>
              </template>
            </el-table-column> -->

            <el-table-column
              align="center"
              label="人员绩效明细"
              width="500"
              :show-overflow-tooltip="false"
            >
              <template slot-scope="scope">
                <div>
                  <template v-for="(i, j) in scope.row.userAssignment">
                    <template v-if="i.ruleCode == scope.row.ruleCode">
                      <div :key="j">
                        <div class="userProportion">
                          <template v-if="i.businessManagerSign === 'HAVE'"
                            ><span
                              >经理:<el-tag type="warning" style="width: 85px; margin-left: 5px">{{
                                i.userName
                              }}</el-tag></span
                            >
                            <div class="ratio">
                              <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                占比: <span style="color: red">{{ i.nowUserRatio || '0' }}%</span>
                              </div>
                              <div v-else>
                                金额: <span style="color: red">{{ i.nowUserMoney || 0 }}元</span>
                              </div>
                              <div>
                                绩效奖金:<el-input
                                  disabled
                                  style="width: 90px; margin-left: 5px"
                                  v-model="i.performanceValue"
                                  @input="$forceUpdate()"
                                ></el-input
                                >元
                              </div>
                            </div>
                          </template>

                          <template v-else>
                            <template v-if="i.userType === 'MEMBER'">
                              <span
                                >成员:<el-tag style="width: 85px; margin-left: 5px">{{
                                  i.userName
                                }}</el-tag></span
                              >
                              <div class="ratio">
                                <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                  占比: <span style="color: red">{{ i.nowUserRatio || '0' }}%</span>
                                </div>
                                <div v-else>
                                  金额: <span style="color: red">{{ i.nowUserMoney || 0 }}元</span>
                                </div>
                                <div>
                                  绩效奖金:<el-input
                                    disabled
                                    style="width: 90px; margin-left: 5px"
                                    onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                    v-model="i.performanceValue"
                                    @input="$forceUpdate()"
                                  ></el-input
                                  >元
                                </div>
                              </div>
                            </template>
                            <template v-if="i.userType === 'DEPT'">
                              <span
                                >部门:<el-tag
                                  type="success"
                                  style="width: 85px; margin-left: 5px"
                                  >{{ i.deptName }}</el-tag
                                ></span
                              >
                              <div class="ratio">
                                <div v-if="scope.row.contentType == 'RATIO_TYPE'">
                                  占比: <span style="color: red">{{ i.nowUserRatio || '0' }}%</span>
                                </div>
                                <div v-else>
                                  金额: <span style="color: red">{{ i.nowUserMoney || 0 }}元</span>
                                </div>
                                <div>
                                  绩效奖金:<el-input
                                    disabled
                                    style="width: 90px; margin-left: 5px"
                                    onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                                    v-model="i.performanceValue"
                                    @input="$forceUpdate()"
                                  ></el-input
                                  >元
                                </div>
                              </div>
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-descriptions :column="2" border>
            <el-descriptions-item label="合计" labelStyle="width:15%">
              <span style="color: red"> {{ staffTotal }}元</span>
            </el-descriptions-item>
            <el-descriptions-item label="金额大写" labelStyle="width:15%">
              <span style="color: red"> {{ staffTotalInChinese }}</span>
            </el-descriptions-item>
          </el-descriptions>
          <div style="height: 40px"></div>

          <!-- <OpinionArea :id="ID"></OpinionArea> -->
        </div>
        <div class="fullScreenOperation" v-if="distriBution">
          <el-button class="return" type="info" @click="returnFn">返回</el-button>
        </div>
      </div>
    </div>

    <EditDialog width="70%" :isShow.sync="costDialog_show">
      <div slot="title" class="title">
        <div>个人报销明细</div>
      </div>
      <div slot="content" class="content" style="min-height: 400px">
        <el-card v-loading="costListLoading">
          <el-table :data="costDialog_data.costList" border height="350" style="width: 100%">
            <el-table-column type="index" align="center" label="序号" width="70"> </el-table-column>
            <el-table-column prop="date" align="center" label="记账时间"
              ><template slot-scope="scope">
                {{ scope.row.createdDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column prop="moduleType" min-width="120" align="center" label="记账类型">
              <template slot-scope="scope">
                {{ scope.row.moduleType | dict(dictData.moduleType) }}
              </template>
            </el-table-column>
            <el-table-column prop="sumCost" align="center" label="报销金额"> </el-table-column>
            <el-table-column prop="costTally" align="center" label="记账金额"> </el-table-column>
            <el-table-column
              prop="staffBusinessPerformance"
              width="150"
              align="center"
              label="操作"
            >
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="showCostDetail(scope.row)"
                  :loading="costDetailLoading"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="handleCostSizeChange"
              @current-change="handleCostCurrentChange"
              :current-page="costListParams.pageNow"
              :page-sizes="[5, 10, 50, 100]"
              :page-size="10"
              layout="total, sizes, prev, pager, next, jumper"
              :total="costListParams.total"
            >
            </el-pagination>
          </div>
        </el-card>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" plain @click="costDialog_show = false">返回</el-button>
      </div>
    </EditDialog>

    <BusinessPerformanceDetailDialog
      v-if="costDetailDialog_show"
      :costDetailDialog_show="costDetailDialog_show"
      :costDetail="costDialog_data.costDetail"
      :type="costDialog_data.moduleType"
      :imgList="imgList"
      @closeDialog="handleCloseCostDetail"
    ></BusinessPerformanceDetailDialog>
  </div>
</template>

<script>
import { numberToCurrencyNo, delcommafy, changeMoneyToChinese } from '@/util/jsencrypt'
import { Message } from 'element-ui'
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    OpinionArea: () => import('@/components/OpinionArea.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DialogImg: () => import('@/components/DialogImg.vue'),
    BusinessPerformanceDetailDialog: () =>
      import('@/components/BusinessPerformanceDetailDialog.vue'),
  },

  props: {
    isDetail: {
      type: Number,
      default: 0,
    },
    showEditDialog: {
      type: Boolean,
      default: true,
    },
    ID: {
      type: [Number, String],
      default: '',
    },
    isManage: {
      //是否商务填报进来
      type: Boolean,
      default: false,
    },
    distriBution: {
      type: Boolean,
      default: false,
    },
    Disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      businessInformation: state => state.business.businessInformation,
      userAssignmentMap: state => state.business.userAssignmentMap,
      CompanyMembers: state => state.business.CompanyMembers,
      userInfo: state => state.user.userInfo,
    }),

    // 报销单金额合计
    amount() {
      let sum = 0
      if (this.costDialog_data.costDetail.detailList) {
        sum = this.costDialog_data.costDetail.detailList.reduce(
          (total, item) => (total += Number(item.cost)),
          0
        )
      }
      return sum
    },
  },
  data() {
    return {
      value: '',
      Index: 0,
      AchievementInfo: {},
      tableData: [
        [
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '负责关键沟通与组织',
            ratioRule: '90%减以上3、4、5项',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '提供有效项目信息',
            ratioRule: '100元或1%(低于200元按200元)',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '商务跟进',
            ratioRule: '0%~20%',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '有效引荐',
            ratioRule: '0%~30%',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'REFERRER',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '售前和投标技术方案编制',
            // workContent: '投标文件技术方案编制',
            ratioRule: '0%~40%',
            participatingDistributor: '售前和投标技术方案编制部门',
            // participatingDistributor: '投标技术方案编制部门',
            instructionMatters: '',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
            // ruleCode: 'BIDDING_SCHEME',
          },
          {
            businessType: '无事前沟通竞标类项目',
            workContent: '其他',
            participatingDistributor: '公司',
            instructionMatters: '',
            ratio: 9,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRule: '剩余比例',
          },
        ],
        [
          {
            businessType: '有事前沟通新客户项目',
            workContent: '负责关键沟通与组织',
            ratioRule: '97%减以上3、4、5项',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '提供有效项目信息',
            ratioRule: '1%~3%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '商务跟进',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
            ratioRule: '0%~20%',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '有效引荐',
            ratioRule: '0%~30%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'REFERRER',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '售前和投标技术方案编制',
            ratioRule: '0%~20%',
            ratio: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            money: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '售前和投标技术方案编制部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
          },
          {
            businessType: '有事前沟通新客户项目',
            workContent: '其他',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '公司',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRule: '剩余比例',
          },
        ],
        [
          {
            businessType: '有事前沟通老客户项目',
            workContent: '负责关键沟通与组织',
            ratioRule: '97%减3、4、5、6项',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务负责人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'LEAD_COMMUNICATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '提供有效项目信息',
            ratioRule: ' 1%~3%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '信息提供人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROVIDE_INFORMATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '商务跟进',
            ratioRule: '0%~20%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '商务助理',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'BUSINESS_FOLLOW_UP',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '有效引荐（历史商务关系）',
            ratioRule: '0%~30%',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '引荐人',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'HISTORICAL_BUSINESS_REFERRER',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '售前和投标技术方案编制',
            ratioRule: '0%~20%',
            ratio: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            money: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '售前和投标技术方案编制部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'CONSULT_BIDDING_SCHEME',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '驻场或原项目实施',
            ratioRule: '0%~20%',
            ratio: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            money: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '驻场团队或原实施团队所在部门',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'PROJECT_IMPLEMENTATION',
          },
          {
            businessType: '有事前沟通老客户项目',
            workContent: '其他',
            ratioRule: '剩余比例',
            ratio: 0,
            money: 0,
            interzonalExplain: '',
            managerRatio: 0,
            temporaryRatio: 0,
            contentType: 'RATIO_TYPE',
            participatingDistributor: '公司',
            instructionMatters: '',
            userAssignment: [],
            ruleCode: 'OTHER',
            ratioRuleDetail: '',
          },
        ],
      ],
      ratio: '',
      newRatioL: '',
      opinionPerformanceRecordList: [],
      businessManage: [],
      dialogFormVisible: false,
      loading: false,
      disabled: this.Disabled || false,
      dictData: {
        businessType: [],
        businessDisbursenentType: [],
        projectSource: [],
        companyType: [],
        businessLines: [],
        moduleType: [],
      },

      NBSCFY_ratio_show: false, // 内部生产费用弹框显示隐藏
      WXSCFY_detail_show: false, //外协生产费用详情弹框显示隐藏
      costDialog_show: false, //报销信息弹框显示隐藏
      costDetailDialog_show: false,

      isNBSCFYButtonLoading: false,
      isWXSCFYButtonLoading: false,
      isCostDialogLoading: false, //报销详情button按钮的loading
      costListLoading: false, //弹框右侧表格loading
      costDetailLoading: false, //弹框内详情按钮loading

      costListParams: {
        pageSize: 10,
        pageNow: 1,
        total: 0,
        businessManageId: '',
        targetId: '',
      }, //员工报销详情右侧弹框请求参数

      NBSCFY_ratio_data: {},
      WXSCFY_detail_data: {},
      costDialog_data: {
        moduleType: '',
        selectRole: '',
        costList: [], //右侧员工明细
        costDetail: {},
      },
      oldRegistrationInfo: {}, //原住处登记单数据
      registrationIsShow: false, //支出登记单展示对话框

      img_index: 0, //附件图片预览下标
      imgList: [], //附件图片数组
      dialogVisible: false, //图片预览
      filepath: process.env.VUE_APP_FILEPATH,

      instInvolved: {}, //当前进度处理人信息

      companyTypeList: [], //公司单位数组

      staffTotal: '',
      staffTotalInChinese: '',
    }
  },
  created() {
    this.$api.dict
      .listSysDictData('COMPANY_TYPE', true)
      .then(res => {
        this.dictData.companyType = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_TYPE', true)
      .then(res => {
        this.dictData.businessType = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('BUSINESS_LINES', true)
      .then(res => {
        this.dictData.businessLines = res.data
      })
      .catch(err => {
        console.log(err)
      })
    this.$api.dict
      .listSysDictData('BUSINESS_MODULE_TYPE', true)
      .then(res => {
        this.dictData.moduleType = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    // 添加部门前缀
    addDept(val, n) {
      let name = ''
      if (n && n.length > 0) {
        for (let index = 0; index < n.length; index++) {
          const v = n[index]
          if (v.userName == val) {
            var newArr = v.departmentName.split(',')
            name = v.userName + '-' + newArr[0]
            break
          }
        }
      } else {
        name = val
      }
      return name
    },

    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },

    amountToConvert(n) {
      if (!n) return
      n = Number(n).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      return n
    },
  },
  watch: {
    ID: {
      immediate: true,
      handler(val) {
        this.getData()
      },
    },
    'businessManage.performanceStatus': {
      immediate: true,
      handler(val) {
        if (val != 0 && val) {
          this.disabled = true
        }
      },
    },
  },
  methods: {
    async getData() {
      this.loading = true
      if (this.ID) {
        this.opinionPerformanceRecordList = []
        const res = await this.$api.businessPerformance.getCalculatePerformanceInfoToStaff(this.ID)
        if (res.data.businessManage) {
          // 处理我方合同金额
          res.data.businessManage.ourContractAmount = numberToCurrencyNo(
            res.data.businessManage.ourContractAmount
          )
          // 处理税费计算总税费
          res.data.businessManage.taxesFee = numberToCurrencyNo(res.data.businessManage.taxesFee)
          //处理投标费用
          res.data.businessManage.tenderFee = numberToCurrencyNo(res.data.businessManage.tenderFee)
          res.data.businessManage.interiorBusinessPerformance = numberToCurrencyNo(
            res.data.businessManage.interiorBusinessPerformance
          )
          res.data.businessManage.businessDeptPerformance = numberToCurrencyNo(
            res.data.businessManage.businessDeptPerformance
          )
          res.data.businessManage.actualPerformance = numberToCurrencyNo(
            res.data.businessManage.actualPerformance
          )

          if (res.data.businessManage.businessType === 'WSQGTJBLXM') {
            this.value = '无事前沟通竞标类项目'
            this.Index = 0
          } else if (res.data.businessManage.businessType === 'YSQGTXKHXM') {
            this.value = '有事前沟通新客户项目'
            this.Index = 1
          } else if (res.data.businessManage.businessType === 'YSQGTLKHXM') {
            this.value = '有事前沟通老客户项目'
            this.Index = 2
          }
          this.$store.commit({
            type: 'business/SET_BUSINESS_INFORMATION',
            businessInformation: res.data.businessManage,
          })
          this.$store.commit({
            type: 'business/SET_USER_ASSIGNMENT_MAP',
            userAssignmentMap: res.data.userAssignmentMap,
          })
          this.businessManage = res.data.businessManage
        }
        if (res.data.userAssignmentMap) {
          let total = res.data.userAssignmentMap.reduce((total, item) => {
            return (total += item.performanceValue)
          }, 0)
          this.staffTotal = numberToCurrencyNo(total)
          this.staffTotalInChinese = changeMoneyToChinese(total)
        }
        this.tableData[this.Index].forEach((v, i) => {
          res.data.businessPerformanceList.forEach((k, j) => {
            if (v.ruleCode === k.ruleCode) {
              v.contentType = k.contentType
              v.money = k.nowMoney
              v.ratio = k.nowRatio
              v.id = k.id
              v.instructionMatters = k.instructionMatters
              if (res.data.businessManage.calculatePerformanceStatus === 0) {
                // 说明该商务绩效被保存过，直接调用服务器返回值
                v.performanceTotalMoney = k.nowPerformanceValue
                v.performanceTotalMoneyFormat = numberToCurrencyNo(v.performanceTotalMoney)
              } else {
                if (v.contentType == 'MONEY_TYPE') {
                  v.performanceTotalMoney = v.money
                  v.performanceTotalMoneyFormat = v.money
                } else {
                  v.performanceTotalMoney =
                    v.ratio *
                    Number(delcommafy(this.businessInformation.businessTeamPerformance)) *
                    0.01
                  if (v.performanceTotalMoney !== 0) {
                    v.performanceTotalMoney = v.performanceTotalMoney.toFixed(2)
                    v.performanceTotalMoneyFormat = numberToCurrencyNo(v.performanceTotalMoney)
                  }
                }
              }
            }
          })
        })

        if (res.data.userAssignmentMap !== null) {
          this.tableData[this.Index].forEach((v, i) => {
            res.data.userAssignmentMap.forEach(k => {
              if (v.ruleCode === k.ruleCode) {
                v.userAssignment.push(k)
              }
            })
            v.userAssignment.forEach(userItem => {
              userItem.performanceValue = userItem.performanceValue ? userItem.performanceValue : 0
            })
          })
        }
        this.tableData[this.Index] = this.tableData[this.Index].filter(item => item.id)
      }
      this.loading = false
    },

    max_change(row) {
      if (row.ratio < row.percentage[0]) {
        row.ratio = row.percentage[0]
        row.showtips = true
        setTimeout(function () {
          row.showtips = false
        }, 2000)
      } else if (row.ratio > row.percentage[1]) {
        row.ratio = row.percentage[1]
        row.showtips = true
        setTimeout(function () {
          row.showtips = false
        }, 2000)
      } else {
        row.showtips = false
      }
    },
    returnFn() {
      this.$emit('update:showEditDialog', false)
      this.$emit('updateData')
    },

    //todo 获取右侧员工详情报销列表数据
    async getCostList() {
      this.costListLoading = true
      this.costDialog_data.costList = []
      this.costListParams.businessManageId = this.businessInformation.id
      this.costListParams.targetId = this.userInfo.staffId
      const { data: res } = await this.$api.businessTally.listToStaff(this.costListParams)
      this.costListLoading = false
      if (res?.records) {
        this.costListParams.total = res.total
        this.costDialog_data.costList = res.records
      }
    },

    showCostDialog() {
      this.costDialog_data = {
        costList: [],
        costDetail: {},
      }
      this.isCostDialogLoading = true
      this.costDialog_show = true
      this.getCostList()
      this.isCostDialogLoading = false
    },

    handleCostSizeChange(val) {
      this.costListParams.pageSize = val
      this.costListParams.pageNow = 1
      this.getCostList()
    },
    handleCostCurrentChange(val) {
      this.costListParams.pageNow = val
      this.getCostList()
    },

    // 打开报销详情信息对话框
    async showCostDetail(val) {
      this.costDetailLoading = true
      this.costDialog_data.costDetail = {}
      this.getCompanyList()
      this.costDialog_data.moduleType = val.moduleType
      if (val.moduleType == 'BUSINESS_EXPENSE') {
        const { data: res } = await this.$api.businessExpense.selectDetailById(val.professionalId)
        this.costDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      } else if (val.moduleType == 'BUSINESS_REGISTRATION_EXPENDITURE') {
        const { data: res } = await this.$api.register.selectDetailById(val.professionalId)
        this.costDetailLoading = false
        if (res) {
          this.costDialog_data.costDetail = res
        }
      }
      this.costDialog_data.costDetail.detailList.forEach(item => {
        item.expenseFileList.forEach(v => {
          if (!this.isShowType(v)) {
            this.imgList.push(v)
          }
        })
      })
      this.costDetailDialog_show = true
    },

    handleCloseCostDetail() {
      this.costDetailDialog_show = false
    },

    getCompanyList() {
      this.$api.dict
        .listSysDictData('COMPANY_TYPE')
        .then(res => {
          this.companyTypeList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    isShowType(n) {
      let arr = n.filePath.split('.')
      let str = arr[arr.length - 1]
      let srtArr = [
        'bmp',
        'dib',
        'pcp',
        'dif',
        'wmf',
        'gif',
        'jpg',
        'tif',
        'eps',
        'psd',
        'cdr',
        'iff',
        'tga',
        'pcd',
        'mpt',
        'png',
        'jpeg',
      ]
      if (srtArr.indexOf(str) == -1) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/config.scss';
.userProportion {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
  margin-bottom: 5px;
  .ratio {
    margin-left: 50px;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
}

/deep/.my-label {
  width: 25%;
}
/deep/.my-content {
  width: 25%;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fff;
  border-color: #e4e7ed;
  color: #000;
}

/deep/ .el-table th {
  background: #ddeeff;
}
/deep/ .undefined {
  border-bottom: 0px;
}

/deep/.el-table__cell {
  border-left: 1px solid #ebeef5 !important;
}

.below-content {
  display: flex;
  justify-content: space-between;
}
/deep/.height-line {
  color: red;
  font-weight: 700;
}

.title-with-button {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.cost-dialog-title {
  margin-bottom: 10px;
}

/deep/.businessOutSource {
  //费用类别背景色
  background-color: #b2f3fe;
  border: 1px solid #ddd !important;
}

.img_txt {
  text-decoration: underline;
  color: #409eff;
  &:hover {
    cursor: pointer;
  }
  .content {
    padding: 10px;
  }
}

/deep/.el-descriptions__title {
  width: 100% !important;
}
.info-title-with-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/deep/.projectBGC {
  //背景色
  background-color: #d0f6fd !important;
  border: 1px solid #ddd !important;
}
/deep/.el-loading-spinner {
  margin-top: 21%;
}
</style>
